<template>
  <div class="team">
    <v-card class="white pa-3">
      <h1 class="text-center mb-3 subtitle-4 black--text">
        اتصل بنا
      </h1>
      <v-row
        v-if="isPageNotLoading"
        class="mt-5"
      >
        <!-- facebook -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="data.contact_facebook"
            dense
            append-icon="fab fa-facebook"
            label="فيسبوك"
            outlined
          ></v-text-field>
        </v-col>
        <!-- whatsapp -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="data.contact_whatsapp"
            dense
            append-icon="fab fa-whatsapp"
            label="واتساب"
            outlined
          ></v-text-field>
        </v-col>
        <!-- insta -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="data.contact_insta"
            dense
            append-icon="fab fa-instagram"
            label="انستغرام"
            outlined
          ></v-text-field>
        </v-col>
        <!-- website -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="data.contact_web"
            dense
            append-icon="fa-globe-americas"
            label="الموقع الالكتروني"
            outlined
          ></v-text-field>
        </v-col>
        <!-- snap -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="data.contact_snap"
            dense
            append-icon="fab fa-snapchat-ghost"
            label="سناب"
            outlined
          ></v-text-field>
        </v-col>
        <!-- telegram -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="data.contact_telegram"
            dense
            append-icon="fab fa-telegram"
            label="تلغرام"
            outlined
          ></v-text-field>
        </v-col>
        <!-- phone -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="data.contact_phone"
            dense
            append-icon="fa-phone-alt"
            label="الهاتف"
            outlined
          ></v-text-field>
        </v-col>
        <!-- location -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="data.contact_location"
            dense
            append-icon="fa-map-marker-alt"
            label="الموقع"
            outlined
          ></v-text-field>
        </v-col>
        <!-- note -->
        <v-col cols="12">
          <v-textarea
            v-model="data.contact_note"
            name="input-7-1"
            label="النبذة"
            append-icon="fa-scroll"
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="mt-10 mb-2 d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>

      <div
        v-if="isPageNotLoading"
        class="d-flex justify-center mt-2"
      >
        <v-btn
          :loading="editBtnLoading"
          color="primary"
          @click="submitAdd()"
        >
          تعديل
        </v-btn>
      </div>
    </v-card>
    <!--- Dailog for show info to user-->
    <v-dialog
      v-model="dialogData.open"
      max-width="500px"
    >
      <v-toolbar
        :color="dialogData.color"
        dense
      ></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center">
          {{
            dialogData.bodyText
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogData.open = false"
          >
            تم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      editBtnLoading: false,

      isFormValid: false,

      isPageNotLoading: true,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      data: {
        contact_facebook: null,
        contact_whatsapp: null,
        contact_insta: null,
        contact_web: null,
        contact_snap: null,
        contact_telegram: null,
        contact_phone: null,
        contact_location: null,
        contact_note: null,
      },
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      this.isPageNotLoading = false
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.$http
        .get('contact')
        .then(Response => {
          this.isPageNotLoading = true
          if (Response.data.error === false) {
            this.data = Response.data.results
          }
        })
        .catch(error => {
          this.isPageNotLoading = true
          console.log(error)
        })
    },

    submitAdd() {
      this.editBtnLoading = true
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .put('contact', this.data)
        .then(Response => {
          this.editBtnLoading = false
          if (Response.data.error === false) {
            this.showDialogfunction(Response.data.results, 'primary')
          } else {
            this.showDialogfunction(Response.data.results, '#FF5252')
          }
        })
        .catch(error => {
          this.editBtnLoading = false
          this.showDialogfunction('حدث خطأ يرجى المحاولة مجددا', '#FF5252')
          console.log('error', error)
        })
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },
  },
}
</script>

<style scoped>
.teacher_image_table {
  cursor: pointer;
  width: 109px;
  height: 100px;
}
</style>

<style scoped>
a {
  text-decoration: none;
}
</style>
